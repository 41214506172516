import ReactDOM from "react-dom";

import { CaseManager, APPS } from "@flow/case-manager";

import * as serviceWorker from "./serviceWorker";
import "./index.css";

import { queueDefinition } from "./configuration/queues";
import { caseConfig } from "./configuration/case";
import customTranslations from "./translations";
import { searchConfiguration } from "./configuration/searchConfiguration";
import {
  MortgageProviderIds,
  mortgageProviders,
} from "./generated/mortgage-providers";

const FlowPortalWithApps = (
  <CaseManager
    configuration={({ profile }) => {
      const roles = profile.role;
      const showAllBanks =
        !!(roles?.includes("admin") ||
        roles?.includes(MortgageProviderIds.LOKALBANK));


      return {
        apps: [APPS.Inbox, APPS.Search, APPS.Process],
        inboxConfiguration: {
          queuesPollingEnabled: true,
        },
        customComponents: {
          queueDefinition,
          case: caseConfig,
          menu: {
            menuStructure: mortgageProviders
              .filter((provider) => {
                return showAllBanks || roles?.includes(provider.id);
              })
              .map((provider) => ({
                name: provider.name,
                elems: [
                  `${provider.id}-active-cases-queue`,
                  `${provider.id}-failed-cases-queue`,
                  `${provider.id}-completed-cases-queue`,
                ],
                isDefaultOpen: !showAllBanks,
              })),
          },
        },
        searchConfiguration,
        language: "no",
        translates: [
          {
            language: "no",
            translates: customTranslations.no,
          },
          {
            language: "en",
            translates: customTranslations.en,
          },
        ],
      };
    }}
  />
);

ReactDOM.render(FlowPortalWithApps, document.getElementById("root"));

serviceWorker.unregister();
